<template>
  <div class="w-full container mx-auto flex-1 flex flex-col items-center justify-center py-20">
    <h2 class="font-bold text-4xl">Accepting Invitation</h2>

    <div v-if="working" class="mt-24 flex items-center justify-center space-x-16">
      <loading-icon class="h-3 text-pink-500" />
    </div>

    <div v-if="! working && error" class="mt-24 sm:max-w-xl w-full">
      <div class="rounded-md bg-red-100 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm leading-5 font-medium text-red-800">
              An error occured
            </h3>
            <div class="mt-4 text-sm leading-5 text-red-700">
              <p>{{ error }}</p>
              <p class="mt-2">Please try again or contact support.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'TeamInvitations',

  data() {
    return {
      working: false,
      error: null,
      userWait: 0,
    }
  },

  computed: {
    authenticated() {
      return this.$store.getters['auth/authenticated']
    },

    user() {
      return this.$store.getters['auth/getUser']
    },

    invitationId() {
      return this.$route.params.invitationId
    }
  },

  methods: {
    waitForUser() {
      return new Promise(resolve => {
        let interval = setInterval(() => {
          if (! this.user.id && this.userWait < 10) {
            return this.userWait++
          }

          clearInterval(interval)

          if (this.user.id ) {
            return resolve(true)
          }

          resolve(false)
        }, 300)
      })
    },

    async handleInvitation() {
      this.working = true

      try {
        await api.get(`/users/me/team/invitations/${this.invitationId}`, {
          params: {
            signature: this.$route.query.signature
          }
        })

        this.$toast.success('Invitation accepted!')

        setTimeout(() => {
          this.$store.dispatch('auth/logout')
            .finally(() => {
              window.location.href = '/login'
            })
        }, 2000)
      } catch(error) {
        this.working = false

        if (! error.response) {
          console.error(error)
          this.error = 'Unknown error'
          return
        }

        if (error.response.status == 404) {
          this.error = 'This invitation is no longer valid.'
          return
        }

        this.error = error.response.data.message
      }
    }
  },

  mounted() {
    if (! this.invitationId) {
      console.log('invitation id missing, redirecting')
      return this.$router.push({name: 'Home'})
    }

    if (! this.authenticated) {
      console.log('unauthenticated, redirecting')
      localStorage.setItem('redirect_path', this.$route.fullPath)
      return window.location = `/register/?invited=true&email=${this.$route.query.email}`
    }

    this.waitForUser().then(hasUser => {
      if (! hasUser) {
        console.log('user not loaded, redirecting')
        localStorage.setItem('redirect_path', this.$route.fullPath)
        return window.location = `/register/?invited=true&email=${this.$route.query.email}`
      }

      if (this.user.email != this.$route.query.email) {
        this.error = "Your account email address does't match the invited email address."
        return
      }

      this.handleInvitation()
    })
  }
}
</script>
